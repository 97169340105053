import React, { Component } from "react"
import PropTypes from "prop-types"
import { getMonthYearFromStrings } from "../../utils/utils"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ImageMeta from "../../components/ImageMeta"

var classNames = require("classnames")

function FixedFacewallHomepage() {
  return (
    <div className="columns">
      <div className="column is-2"></div>
      {/* <FacewallImage
            url="es/chriselda"
            reviewerName="Chriselda"
            monthYear={{
                year: "2020",
                month: "2"
              }}
              imageUrl="AOMS/DEV/testimonial-carousel-chriselda-ext-di"
            />
            <div className="column is-2"></div>
            <FacewallImage
            url="es/edith"
            reviewerName="Edith"
            monthYear={{
                year: "2020",
                month: "2"
              }}
              imageUrl="AOMS/DEV/testimonial-carousel-edith-wt_spanish"
            /> */}
      <FacewallImage
        url="es/chriselda"
        reviewerName="Chriselda"
        monthYear={{
          year: "2020",
          month: "2"
        }}
        imageUrl="AOMS/DEV/testimonial-desktop-chriselda-ext-di"
      />
      <div className="column is-2"></div>
      <FacewallImage
        url="es/edith"
        reviewerName="Edith"
        monthYear={{
          year: "2020",
          month: "2"
        }}
        imageUrl="AOMS/DEV/testimonial-desktop-edith-wt_spanish"
      />
      <div className="column is-2"></div>
    </div>
  )
}

FixedFacewallHomepage.propTypes = {
  reviews: PropTypes.array.isRequired,
  className: PropTypes.string,
  middleColumnsis: PropTypes.number,
  sideColumnIs: PropTypes.number,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  heading: PropTypes.string,
  blurb: PropTypes.element
}

function FacewallImage(props) {
  return (
    <div className="column fixed-facewall-item has-text-centered">
      <Link to={`/${props.url.toLowerCase()}/`}>
        <ImageMeta
          cloudName="nuvolum"
          publicId={props.imageUrl}
          width="1600"
          height="1600"
          gravity="face"
          crop="crop"
          responsive
          responsiveUseBreakpoints="true"></ImageMeta>
      </Link>
      <Link className="image-caption-link" to={`/${props.url.toLowerCase()}/`}>
        <div className="image-caption-wrapper">
          <h6 className="image-caption staff fixed-facewall-name">
            Conoce a {props.reviewerName}
          </h6>
          <h6 className="image-caption staff staff-title light fixed-facewall-time">
            {getMonthYearFromStrings(
              props.monthYear.year,
              props.monthYear.month
            )}
          </h6>
        </div>
      </Link>
    </div>
  )
}

export default FixedFacewallHomepage
